<template>
  <microsoft-csp />
</template>

<script>
import MicrosoftCsp from "../../components/solutions/volume-licensing/MicrosoftCsp.vue";
export default {
  name: "microsoft-csp-page",
  components: { MicrosoftCsp },
};
</script>
